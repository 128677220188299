
import { Options, Vue, prop } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  // Custom message to display as content/text
  // Default: Empty string | Type: String
  customMessage = prop<string>({
    default: "",
    type: String
  });

  // Header text/message displayed above main content
  // Default: Empty string | Type: String
  customHeaderMessage = prop<string>({
    default: "",
    type: String
  });

  // Controls whether action button is visible/functional
  // Required: No | Default: undefined | Type: Boolean
  buttonAction = prop<boolean>({
    required: false,
    type: Boolean
  });

  // Icon identifier for displaying visual feedback (default is "noData_empty" state)
  // Default: "noData_empty" | Type: String
  icon = prop<string>({
    default: "noData_empty",
    type: String
  });

  // Additional CSS classes for custom styling container
  // Default: Empty string | Type: String
  customClass = prop<string>({
    default: "",
    type: String
  });

  // Loading state indicator (shows/hides loading animation)
  // Default: false | Type: Boolean
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });

  // Text label for the action button
  // Default: Empty string | Type: String
  buttonTitle = prop<string>({
    default: "",
    type: String
  });

  // Left-aligned icon for the action button
  // Default: Empty string | Type: String
  buttonIconLeft = prop<string>({
    default: "",
    type: String
  });

  // Custom text color for the button (hex code or CSS color value)
  // Default: Empty string | Type: String
  buttonTextColor = prop<string>({
    default: "",
    type: String
  });

  // Additional CSS classes for customizing the icon
  // Default: Empty string | Type: String
  customIconClass = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  emits: ["action"],
  components: {}
})
export default class EmptyList extends Vue.with(Props) {
  get accountIForeign() {
    return AccountController.accountData.accountIsForeign;
  }
}
