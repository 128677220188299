
/* eslint-disable @typescript-eslint/camelcase */
import { Options, mixins } from "vue-class-component";
import { SearchFlight, CargoDetailStt } from "@/domain/entities/Cargo";
import {
  formatDateNormal,
  commaDecimalToDecimal,
  firstCapitalize,
  dateTimePayload,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { capitalize } from "vue";
import { OptionsClass, ModalMessageEntities } from "@/domain/entities/MainApp";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import {
  FlightPlanForECargo,
  AddBagOrStt,
  CheckAvailableRequest
} from "@/data/payload/api/CargoApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { flags } from "@/feature-flags";
import { GTMBookingCargoEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/booking-cargo-event";
import { gtmCargoData } from "../../../modules/gtm";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";

@Options({
  props: {
    dataFlight: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  }
})
export default class ScheduleCardV2 extends mixins(OutGoingMixins) {
  withPrint = false;
  withPrintLabel = false;
  countSuccess = 0;
  countFailed = 0;
  formatDateNormal = formatDateNormal;
  props: any = this.$props;

  get flightData(): SearchFlight {
    return this.props.dataFlight;
  }

  get formBooking() {
    return CargoController.form;
  }

  get partnerName() {
    return capitalize(this.dataProfile.account_type_detail.company_name);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get typeUser() {
    return this.dataProfile.normalizedTypeUser;
  }

  // origin city
  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }
  get destinationCity(): OptionsClass {
    return CargoController.destinationCity;
  }

  get isRtc() {
    return (
      ReadyToCargoController.flowBooking === "rtc-process" ||
      ReadyToCargoController.flowBooking === "rtc-edit"
    );
  }

  get sttResult(): CargoDetailStt[] {
    return CargoController.sttResultFinal;
  }

  get totalStt() {
    return this.sttResult.length;
  }

  get totalPieces() {
    return CargoController.totalSttPieces;
  }

  get detailDistrict() {
    return LocationController.districtDetail;
  }

  get rcId(): number {
    return Number(this.$route.query.rcId);
  }

  get formDetailPieces() {
    return CargoController.formDetailPieces;
  }

  get isHaveBeenSubmitDetailPieces() {
    return CargoController.isHaveBeenSubmitDetailPieces;
  }

  get mappingDetailPieces() {
    return this.formDetailPieces.dimensionPieces.map((item: any) => {
      return {
        pieces: !this.isHaveBeenSubmitDetailPieces
          ? +this.formBooking.actualTotalSTTPieces
          : +item.pieces,
        length: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.length
          : item.length,
        width: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.width
          : item.width,
        height: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.height
          : item.height
      };
    });
  }

  get dataDetailRetryCargo() {
    return CargoController.cargoDetailData;
  }

  get detail() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  get isRebooking() {
    return this.flowBooking === "rebooking" && this.$route.query.rcId;
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }

  get timestampBooking() {
    return CargoController.timestampBooking;
  }

  get eventName() {
    return ReadyToCargoController.eventName;
  }

  get isPlaneAuto() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto"
    );
  }

  get progress(): number {
    return CargoController.progressValue;
  }

  get withPrintCutOff() {
    return CargoController.withPrintCutOff;
  }

  get withPrintCutOffLabel() {
    return CargoController.withPrintCutOffLabel;
  }

  get cutOffTime() {
    return {
      hours: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(
        ":"
      )[0],
      minutes: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(
        ":"
      )[1]
    };
  }

  get isEditCutoff() {
    return this.isAllowEditCutoff;
  }

  get isAllowEditCutoff() {
    const isEditRtcCutoff =
      ReadyToCargoController.flowBooking === "rtc-edit" &&
      this.isPlaneAuto &&
      this.cutOffTime.hours !== undefined &&
      this.cutOffTime.minutes !== undefined;
    return isEditRtcCutoff;
  }

  get payloadBagNoOnly() {
    return JSON.parse(localStorage.getItem("cargo-bag") || "[]")
      .map((item: any) =>
        item.isFromRebooking ? null : new AddBagOrStt(item.baggingNumber)
      )
      .filter((items: any) => items != null);
  }

  get payloadSttNoOnly() {
    return JSON.parse(localStorage.getItem("cargo") || "[]")
      .filter(
        (item: any) =>
          !JSON.parse(
            localStorage.getItem("cargo-bag") || "[]"
          ).find((bag: any) => JSON.stringify(bag).includes(item.sttNumber)) ||
          JSON.parse(localStorage.getItem("cargo-bag") || "[]").find(
            (itemBag: any) => item.isFromRebooking == itemBag.isFromRebooking // add validation to check on cargo-bag that come from rebooking
          )
      )
      .map((item: any) => {
        if (item.sttDetails) {
          return item.sttDetails.map(
            (item: any) => new AddBagOrStt(undefined, item.sttNumber)
          );
        } else {
          return new AddBagOrStt(undefined, item.sttNumber);
        }
      })
      .flat();
  }

  get payloadOriginAirportCode() {
    return (
      this.formBooking.airportOrigin?.value ||
      LocationController.districtDetail.cityCode
    );
  }
  get payloadDestinationAirportCode() {
    return (
      this.formBooking.airportDestination.value ||
      this.formBooking.destinationCityCode
    );
  }

  get payloadDepartureDate() {
    return this.flightData.departureDate || this.formBooking.estDepartureDate;
  }

  get payloadArrivalDate() {
    return this.flightData.arrivalDate || this.formBooking.estArrivalDate;
  }

  get payloadOverCutOffLimit() {
    return CargoController.isOverCutOffTime || this.detail.isMoreThanCutOffTime;
  }

  convertProduct(name: string) {
    return name.toLowerCase().includes("express") ? "Express" : "REGULAR";
  }

  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "cargo_stt_failed.csv",
      listStt: data
    });
  }

  handleConfirmation() {
    if (this.statusUnavailable) return;

    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-confirmation-general",
        title: "Konfirmasi pembuatan?",
        message: "Pastikan kembali seluruh detail STT sudah benar dan sesuai.",
        onSubmit: this.onCreateCargo,
        onClose: MainAppController.closeMessageModal,
        textSuccess: "Proses",
        textCancel: "Batal",
        customWidth: "417px",
        customButton: "w-full",
        customParentButton: "w-full",
        customWrapperButton: "w-full px-6"
      })
    );
  }

  _mapPayloadFlightPlanForECargo() {
    return this.flightData.itineraries.map((itinerary) => {
      return new FlightPlanForECargo(
        itinerary.dailyFlightNo.toString(),
        true,
        itinerary.flightNo,
        formatDateNormal(itinerary.departureDate, "YYYY-MM-DD"),
        itinerary.originAirportCode,
        itinerary.destinationAirportCode,
        itinerary.departureDate,
        itinerary.arrivalDate,
        itinerary.departureDate,
        +this.formBooking.thresholdTime
      );
    });
  }

  async onCreateCargo() {
    MainAppController.closeMessageModal();

    const origin = this.flightData.originAirportCode;
    const destination = this.flightData.destinationAirportCode;

    const route = `${origin}-${destination}`;

    const resp = await CargoController.onCreateCargo({
      originAirportCode: this.payloadOriginAirportCode,
      destinationAirportCode: this.payloadDestinationAirportCode,
      estTotalCargoGrossweigth: commaDecimalToDecimal(
        this.formBooking.estTotalCargoGrossWeight
      ),
      estTotalCargoVolumeweight: commaDecimalToDecimal(
        this.formBooking.estTotalCargoVolumeWeight
      ),
      partnerName: this.partnerName,
      partnerType: this.typeUser,
      cargoNo: this.formBooking.cargoNumber,
      cargoVehicleNo: this.formBooking.vehicleNumber,
      actualTotalCargoGrossweigth: commaDecimalToDecimal(
        this.formBooking.actualTotalCargoGrossWeight
      ),
      actualTotalCargoVolumeweight: commaDecimalToDecimal(
        this.formBooking.actualTotalCargoVolumeWeight
      ),
      route: !route.includes("undefined") ? route : "", // from nGen
      totalStt: this.totalStt,
      totalPiece: this.totalPieces,
      cargoCommodity: this.formBooking.cargoCommodityCode,
      productCargo: this.convertProduct(this.formBooking.cargoProduct),
      cargoType: this.formBooking.cargoType,
      originCityCode: this.detailDistrict.cityCode,
      destinationCityCode: this.formBooking.destinationCityCode,
      departureDate: dateTimePayload(this.payloadDepartureDate),
      arrivalDate: dateTimePayload(this.payloadArrivalDate),
      bagOrStt: [...this.payloadSttNoOnly, ...this.payloadBagNoOnly],
      isCargoPlaneManual:
        this.formBooking.cargoType === "plane" &&
        this.formBooking.cargoTypeStatus === "manual",
      cargoOverCutoffLimit: this.payloadOverCutOffLimit,
      cargoRtcTurnAuto: true,
      cargoActualPiece: +this.formBooking.actualTotalSTTPieces,
      flightPlanForECargo: this._mapPayloadFlightPlanForECargo(),
      dimensions: this.mappingDetailPieces,
      estDimension: this.formBooking.estDimension,
      actualDimension: this.formBooking.actualDimension,
      isFromRtc: this.isRtc || this.dataDetailRetryCargo.isFromRtc,
      rtcId: this.isRtc ? ReadyToCargoController.readyToCargoDetail.id : 0,
      nog: this.formBooking.nog,
      rebookingId: this.rcId,
      ngenCreatedAt: dateTimePayload(this.formBooking.ngenCreatedAt),
      shc: this.formBooking.shcCode,
      isFromScheduleCard: true
    }).catch(() => {
      this.showModalErrorBookingCargo();
    });
    this.onCreateResponse(resp);
  }

  get isEligibleCargoPlaneRTC() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto" &&
      (this.isRtc || (this.isRebooking && this.isFromRebookingRTC)) &&
      flags.feature_is_booking_cargo_v4.isEnabled()
    );
  }

  isEligibleCargoAWB(resp: any) {
    return resp.cargoNo.startsWith("FLIGHT") || resp.isCargoUsingAwb;
  }

  onCreateResponse(resp: any) {
    if (resp) {
      if (this.isEligibleCargoPlaneRTC) {
        this.showPopupTimeout(resp);
        return;
      } else if (this.isEligibleCargoAWB(resp)) {
        //error Ngen Non RTC
        // this.showModalMessageBookingOnProcess("Booking Kargo Sedang Diproses");
        this._openModalSuccess();
        this.bookingCargoGTM(this.eventName, "success");
      } else if (resp.totalSttFailed && !resp.totalSttSuccess) {
        CargoController.setSttFailed(resp.listSttFailed);
        this.countFailed = resp.totalSttFailed;
        this.showModalErrorBookingCargo();

        // GTM
        this.bookingCargoGTM(this.eventName, "failed");
        const gtmBookingCargoFailed = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto
        }).bookingCargoFailed;

        dataLayer(
          gtmBookingCargoFailed.eventName,
          gtmBookingCargoFailed.property,
          ["timestamp"]
        );
      } else if (resp.totalSttFailed && resp.totalSttSuccess) {
        this.countFailed = resp.totalSttFailed;
        this.countSuccess = resp.totalSttSuccess;
        // this._openModalPartial();
        this.showModalErrorBookingCargo();
        CargoController.setSttFailed(resp.listSttFailed);
        this.onPrint();
      } else {
        // GTM
        this.bookingCargoGTM(this.eventName, "success");
        const gtmBookingCargoSuccess = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto,
          gtmData: {
            cargoNumber: CargoController.cargoIdToGeneratePdf,
            flightNumber: this.formBooking.vehicleNumber
          }
        }).bookingCargoSuccess;

        dataLayer(
          gtmBookingCargoSuccess.eventName,
          gtmBookingCargoSuccess.property,
          ["timestamp"]
        );

        this._openModalSuccess();
        this.onPrint();
      }
    }
  }

  showPopupTimeout(resp: any) {
    const {
      bookingRequestId,
      cargoNo,
      totalSttFailed,
      totalSttSuccess,
      listSttFailed
    } = resp;

    if (bookingRequestId) {
      // Success case
      // this.handleSuccessResponse(bookingRequestId);
      this._openModalSuccess();
    } else if (cargoNo) {
      // Timeout case with cargoNo
      // this.handleTimeoutWithCargoNo();
      this.showModalErrorBookingCargo();
    } else if (totalSttFailed && !totalSttSuccess) {
      // Failed case
      this.handleFailedResponse(totalSttFailed, listSttFailed);
    } else if (totalSttFailed && totalSttSuccess) {
      // Partial success case
      this.handlePartialSuccessResponse(
        totalSttFailed,
        totalSttSuccess,
        listSttFailed
      );
    }
    // Close loading indicator
    MainAppController.closeLoading();
  }

  handleSuccessResponse(bookingRequestId: string) {
    this.showModalMessageBookingOnProcess(
      `Booking Kargo ${bookingRequestId} <br/> Sedang Diproses`
    );
    this.bookingCargoGTM(this.eventName, "success");
  }

  handleTimeoutWithCargoNo() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Booking Kargo Gagal Diproses nGen",
        message:
          "Lihat alasannya di halaman gagal booking. Cek & booking ulang",
        textSuccess: "OK",
        image: "image-modal-failed",
        onSubmit: () => {
          MainAppController.closeMessageModal();
          if (this.progress === 1) {
            CargoController.setIsFromCutOff(true);
            this.$router.push("booking-canceled");
          }
          this.$emit("leave-page", "/cargo/booking-canceled");
        }
      })
    );
  }

  handleFailedResponse(totalSttFailed: number, listSttFailed: any[]) {
    this.countFailed = totalSttFailed;
    CargoController.setSttFailed(listSttFailed);
    this.showModalErrorBookingCargo();
    this.bookingCargoGTM(this.eventName, "failed");

    const gtmBookingCargoFailed = gtmCargoData({
      type: this.formBooking.cargoType,
      isAuto: this.isPlaneAuto
    }).bookingCargoFailed;

    dataLayer(gtmBookingCargoFailed.eventName, gtmBookingCargoFailed.property, [
      "timestamp"
    ]);
  }

  handlePartialSuccessResponse(
    totalSttFailed: number,
    totalSttSuccess: number,
    listSttFailed: any[]
  ) {
    this.countFailed = totalSttFailed;
    this.countSuccess = totalSttSuccess;
    this.showModalErrorBookingCargo();
    CargoController.setSttFailed(listSttFailed);
    this.onPrint();
  }

  showModalMessageBookingOnProcess(title: string) {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title,
        message: "Anda akan mendapat notifikasi jika booking kargo berhasil",
        textSuccess: "OK",
        image: "illustration_waiting_process",
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.onCloseSuccess();
        }
      })
    );
  }

  bookingCargoGTM(eventName: any, result: string) {
    GTMBookingCargoEvent(eventName, {
      cargo_type: this.formBooking.cargoType,
      "3LC_origin": this.detailDistrict.cityCode,
      "3LC_destination": this.formBooking.destinationCityCode,
      booking_result: result,
      cargo_number: CargoController.cargoIdToGeneratePdf,
      timestamp_booking: this.timestampBooking,
      timestamp_get_result: new Date().getTime()
    });
  }

  showModalErrorBookingCargo() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        imageWidth: "32",
        imageHeight: "32",
        customWidth: "417px",
        title: "Pembuatan Gagal !",
        message: "Anda masih dapat menjadwalkan ulang kargo pesawat.",
        textSuccess: "Ok",
        image: "circle-error",
        customButton: "w-full",
        customParentButton: "w-full",
        customWrapperButton: "w-full px-6",
        onSubmit: () => {
          MainAppController.closeMessageModal();
          CargoController.setProgressValue(0);
          if (!this.isRtc && !this.isRebooking) {
            CargoController.setCargoType("");
          }
        }
      })
    );
  }

  _openModalPartial() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        customWidth: "480px",
        title: this.$t("Pembuatan Berhasil !"),
        message: this.$t("cargo.partialCreateMessage", {
          cargoNo: CargoController.cargoIdToGeneratePdf
        }),
        image: "image-modal-warning",
        textSuccess: "Download",
        textCancel: "OK",
        onSubmit: () => {
          this.onDownloadCsvFailedStt(CargoController.sttFailed);
        },
        onClose: () => {
          this.onCloseSuccess();
          MainAppController.closeMessageModal();
        }
      })
    );
  }

  onPrint() {
    const refs: any = this.$refs;
    if (this.withPrint || this.withPrintCutOff) {
      refs.print.printManifestV2(CargoController.cargoIdToGeneratePdf);

      // GTM
      const gtmBookingCargoSuccessPDF = gtmCargoData({
        type: this.formBooking.cargoType,
        isAuto: this.isPlaneAuto,
        gtmData: {
          cargoNumber: CargoController.cargoIdToGeneratePdf,
          flightNumber: this.formBooking.vehicleNumber
        }
      }).bookingCargoSuccessPDF;

      dataLayer(
        gtmBookingCargoSuccessPDF.eventName,
        gtmBookingCargoSuccessPDF.property,
        ["timestamp"]
      );
    }
    if (this.withPrintLabel || this.withPrintCutOffLabel) {
      refs.print.printData(CargoController.cargoIdToGeneratePdf, "label");

      // GTM
      const gtmBookingCargoSuccessLabel = gtmCargoData({
        type: this.formBooking.cargoType,
        isAuto: this.isPlaneAuto,
        gtmData: {
          cargoNumber: CargoController.cargoIdToGeneratePdf,
          flightNumber: this.formBooking.vehicleNumber
        }
      }).bookingCargoSuccessLabel;

      dataLayer(
        gtmBookingCargoSuccessLabel.eventName,
        gtmBookingCargoSuccessLabel.property,
        ["timestamp"]
      );
    }
  }

  _openModalSuccess() {
    const message = CargoController.cargoIdToGeneratePdf.includes("FLIGHT")
      ? this.$t("cargo.successCreateMessage", {
          cargoNo: CargoController.cargoIdToGeneratePdf
        })
      : this.$t("cargo.successCreateMessageV2", {
          cargoNo: CargoController.cargoIdToGeneratePdf
        });

    MainAppController.showMessageModal(
      new ModalMessageEntities({
        imageWidth: "32",
        imageHeight: "32",
        customWidth: "417px",
        title: this.$t("Pembuatan Berhasil !"),
        message,
        image: "badge-success",
        textSuccess: "OK",
        customButton: "w-full",
        customParentButton: "w-full",
        customWrapperButton: "w-full px-6",
        onSubmit: () => {
          this.onCloseSuccess();
          MainAppController.closeMessageModal();
          ReadyToCargoController.setFlowBooking("");
          CargoController.resetCargoData();
        }
      })
    );
  }

  setOpenSuccess(value: boolean) {
    CargoController.setOpenSuccess(value);
  }

  isAllowed: any = false;

  get todayTime() {
    return {
      hours: formatDate(new Date())
        ?.split(",")[1]
        ?.split(":")[0]
        ?.trim(),
      minutes: formatDate(new Date())
        ?.split(",")[1]
        ?.split(":")[1]
        ?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, "")
        ?.trim()
    };
  }

  onCloseSuccess() {
    this.setOpenSuccess(false);
    this.isAllowed =
      +this.cutOffTime.hours < +this.todayTime.hours ||
      (+this.cutOffTime.hours === +this.todayTime.hours &&
        +this.cutOffTime.minutes < +this.todayTime.minutes);
    if (this.isEditCutoff && this.isAllowed) {
      CargoController.setIsFromCutOff(true);
      this.$router.push("/cargo");
    } else {
      if (this.progress === 1) {
        CargoController.setIsFromCutOff(true);
        this.$router.push("booking-success");
      }
    }

    CargoController.setProgressValue(-1);
  }

  loadingCheckAvailable = false;
  isAvailable = false;
  isCheckAvailable = false;
  error = false;

  get statusAvailable() {
    // status available baru valid setelah isCheckAvailable === true
    return this.isCheckAvailable && this.isAvailable;
  }

  get statusUnavailable() {
    return this.isCheckAvailable && !this.isAvailable;
  }

  async onCheckAvailable(e: MouseEvent) {
    try {
      e.stopPropagation(); // agar logic on click card tidak jalan

      CargoController.setDisableDelay(true); // delay disable untuk semua button cek
      setTimeout(() => {
        CargoController.setDisableDelay(false);
      }, 1000); // re enable button cek yang lain

      this.loadingCheckAvailable = true;
      this.error = false;

      const res = await CargoController.checkAvailable(
        new CheckAvailableRequest({
          idRow: this.props.index,
          actualTotalCargoGrossweight: commaDecimalToDecimal(
            this.formBooking.actualTotalCargoGrossWeight
          ),
          actualTotalCargoVolumeweight: commaDecimalToDecimal(
            this.formBooking.actualTotalCargoVolumeWeight
          ),
          itineraries: this.flightData.itineraries
        })
      );
      this.isAvailable = res.isAvailable;
      this.isCheckAvailable = true;
    } catch (error) {
      this.error = true;
    } finally {
      this.loadingCheckAvailable = false;
    }
  }

  get disableDelay(): boolean {
    return CargoController.disableDelay;
  }

  get disabledCheckAvailable(): boolean {
    return this.disableDelay || this.loadingCheckAvailable || this.isAvailable;
  }
}
