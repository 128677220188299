
import { Options, prop, Vue } from 'vue-class-component';
import StatusHandler from '@/app/ui/components/status-handler/index.vue';
import LpButton from '@/app/ui/components/lp-button/index.vue';
import Checkbox from 'primevue/checkbox';
import RadioButton from "primevue/radiobutton";
import { SearchFlight } from '@/domain/entities/Cargo';
import ScheduleCardV2 from './schedule-card-v2.vue';

class Props {
  isLoading = prop<boolean>({
    type: Boolean,
    default: false
  });
  error = prop<string>({
    type: String,
    default: ""
  });
  data = prop<SearchFlight[]>({
    default: () => [],
    type: Object
  });
}

@Options({
  emits: ["onChangeSearch", "onReload"],
  components: {
    StatusHandler,
    Checkbox,
    RadioButton,
    ScheduleCardV2
  }
})

export default class LayoutSchedule extends Vue.with(Props) {

  openFilter = false;
  isSortFilter = true;
  localTransitNumber: any[] = [];
  transitNumber: any[] = [];
  flightTime = "arrival_time";

  transitOptions = [
    {
      name: "Langsung",
      value: 0
    },
    {
      name: "1 transit",
      value: 1
    },
    {
      name: "≥ 2 transit",
      value: 2
    }
  ];

  arrivalAndDepartureOptions = [
    {
      name: "Kedatangan tercepat",
      value: "arrival_time"
    },
    {
      name: "Keberangkatan tercepat",
      value: "departure_time"
    }
  ]

  get headerMessage() {
    const message = ({
      loading: "Daftar jadwal pesawat sedang dimuat",
      internet: "Jaringan internet terputus",
      server: "Terjadi gangguan sistem",
      empty: "Jadwal keberangkatan kargo pesawat tidak tersedia"
    }) as any
    return this.isLoading ? message['loading'] : this.isEmpty? message['empty'] : message[this.error];
  }

  get bodyMessage() {
    const message = ({
      loading: "Kami sedang mencari jadwal yang sesuai untuk Anda.",
      internet: 'Periksa jaringan internal Anda lalu klik "Muat ulang".',
      server: 'Klik "Muat ulang" atau coba beberapa saat lagi.',
      empty: "Ubah & cek kembali pencarian jadwal pesawat Anda."
    }) as any
    return this.isLoading ? message['loading'] : this.isEmpty? message['empty'] : message[this.error];
  }

  get iconStatus() {
    const icon = ({
      internet: "error-internet",
      server: "error-token-ngen",
      empty: "empty-flight-schedule"
    }) as any
    return this.isEmpty ? icon['empty'] : icon[this.error];
  }

  get buttonTitle() {
    const title = ({
      internet: "Muat ulang",
      server: "Muat ulang",
      empty: "Ubah pencarian"
    }) as any
    return this.isEmpty ? title['empty'] : title[this.error];
  }

  get buttonIconLeft() {
    return !this.isEmpty && "refresh-white";
  }

  get filteredFlightSchedules() {
    if (!this.data.length) return [];
    const newData = this.data.sort((a: SearchFlight, b: SearchFlight) =>
      this.flightTime === "departure_time"
        ? a.departureTime - b.departureTime
        : a.arrivalTime - b.arrivalTime
    );
    return newData;
  }

  isFilterTransit(item: SearchFlight) {
    return this.transitNumber.length
      ? this.transitNumber.some((t: number) =>
          t === 2 ? item.transitNumber >= 2 : item.transitNumber === t
        )
      : true;
  }

  get isEmpty() {
    return this.filteredFlightSchedules.length === 0 && this.error === "";
  }

  onActionButtonClicked() {
    if (this.isEmpty) {
      this.$emit("onChangeSearch");
    } else {
      this.$emit("onReload");
    }
  }
}
