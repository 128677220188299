
/* eslint-disable @typescript-eslint/camelcase */
import { Options, prop, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import ScheduleCard from "./schedule-card.vue";
import {
  ellipsisString,
  firstCapitalize,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { GetScheduleListApiRequest } from "@/data/payload/api/NGenApiRequest";
import { FlightSchedule, FlightDetail } from "@/domain/entities/NGen";
import HeaderScheduleCard from "@/app/ui/views/cargo/booking-cargo/components/plane-schedule/header-schedule-card.vue";
import HorizontalScrollContainer from "@/app/ui/components/horizontal-scroll-container/index.vue";
import groupBy from "lodash/groupBy";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { gtmCargoData } from "@/app/ui/views/cargo/modules/gtm";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { defineAsyncComponent } from "vue";
import { FlagsPermissionCargo } from "@/feature-flags/flags-permission-cargo";
import LayoutSchedule from "./layout-schedule-v2.vue";

class Props {
  isShowDate = prop<boolean>({
    type: Boolean,
    default: false
  });
  width = prop<string>({
    type: String,
    default: "w-full"
  });
}
const CargoProcessCargo = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/cargo/booking-cargo/third-step/cargo-process-cargo.vue"
    )
});

@Options({
  emits: ["change-airport"],
  components: {
    HeaderScheduleCard,
    Tooltip,
    ScheduleCard,
    HorizontalScrollContainer,
    CargoProcessCargo,
    LayoutSchedule
  }
})
export default class PlaneSchedule extends Vue.with(Props) {
  mounted() {
    this.flagFeatureFlight ? this.getSearchFlight() : this.onGetFlightSchedule(this.form.flightsData.length);
    this.form.actualTotalCargoGrossWeight = this.cargoInputForm.totalActualCargoGrossWeight.toString();
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  get readyToCargoDetail() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get isRtc() {
    return this.form.isRtc;
  }

  firstCapitalize(str: string) {
    if (str) {
      return firstCapitalize(str.toLowerCase());
    }
    return "";
  }

  get form() {
    return CargoController.form;
  }

  // origin city
  get detailDistrict() {
    return LocationController.districtDetail;
  }

  // list flight schedule
  flightSchedules: FlightSchedule = new FlightSchedule();
  get filteredFlightSchedules() {
    return groupBy(
      this.flightSchedules.flightDetails.filter((data: FlightDetail) => {
        if (!this.form.flightsData.length) {
          return this.$moment(data.DepartureDateTime).isSameOrBefore(
            this.$moment(this.form.estDepartureDate).add(2, "day"),
            "day"
          );
        }
        return this.$moment(data.DepartureDateTime).isBetween(
          this.form.flightsData[this.form.flightsData.length - 1]
            .ArrivalDateTime,
          this.$moment(
            this.form.flightsData[this.form.flightsData.length - 1]
              .ArrivalDateTime
          ).add(2, "day")
        );
      }),
      (flight: FlightDetail) => {
        return this.$moment(flight.DepartureDateTime).format("YYYY-MM-DD");
      }
    );
  }
  async onGetFlightSchedule(amountFlightData: number) {
    this.flightSchedules = await NGenController.onGetScheduleList({
      cityCode: "",
      payload: new GetScheduleListApiRequest({
        Origin: this.form.airports[this.form.flightsData.length]?.airportCode,
        Destination: this.form.airports[this.form.flightsData.length + 1]
          ?.airportCode,
        FlightDate: this.$moment(this.form.estDepartureDate).format(
          "YYYY-MM-DD"
        ),
        GrossWeight: this.form.actualTotalCargoGrossWeight.replace(/,/g, "."),
        VolumeWeight: this.form.actualTotalCargoVolumeWeight.replace(/,/g, "."),
        ETD: "00",
        ProductSno: "37",
        IsRootStation: amountFlightData > 0 ? 1 : 0,
        FlightNo: ""
      })
    });
    // GTM
    if (this.form.isChangeAirport) {
      const gtmProperty = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true,
        gtmData: {
          totalFlights: this.flightSchedules.flightDetails.length
        }
      }).changeAirport;

      dataLayer(gtmProperty.eventName, gtmProperty.property, []);
    } else {
      const flightSchedules = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true,
        gtmData: {
          totalFlights: this.flightSchedules.flightDetails.length
        }
      }).planeSchedule;

      dataLayer(flightSchedules.eventName, flightSchedules.property, []);
    }
  }

  // departure date
  get estDepartureDate() {
    return formatDateWithoutTime(this.form.estDepartureDate);
  }

  get isLoading() {
    return NGenController.isLoading;
  }
  get errorData() {
    return NGenController.errorData;
  }

  ellipsisString(value: string) {
    return ellipsisString(value, this.isShowDate ? 20 : 40);
  }

  get labelTransit() {
    if (this.form.airports.length === 2) {
      return "";
    }
    return `${this.form.airports.length - 2} Transit`;
  }
  get labelPlaneSchedule() {
    if (this.form.airports.length === 2) {
      return "Menampilkan semua kargo pesawat ke kota tujuan Anda.";
    }
    if (this.form.flightsData.length === this.form.airports.length - 2) {
      return `Menampilkan semua kargo pesawat ke bandara tujuan Anda.`;
    }
    return `Menampilkan semua kargo pesawat ke bandara transit ${this.form
      .flightsData.length + 1}`;
  }

  get scheduleFlight() {
    const schedules = [];
    for (let i = 0; i < this.form.airports.length - 1; i++) {
      schedules.push({
        origin: `${this.form.airports[i].airportName}`,
        destination: `${this.form.airports[i + 1].airportName}`
      });
    }
    return schedules;
  }

  moment(value: any) {
    return this.$moment(value);
  }

  get showSelectAirport() {
    return CargoController.showSelectAirport;
  }

  async onBookingCargo() {
    await (this.$refs.bookingCargo as any).onSave();
    CargoController.setIsChangeCargoType(true);
  }

  onLeavePage() {
    ReadyToCargoController.setFlowBooking("");
    CargoController.resetCargoData();
  }

  // v2
  get flagFeatureFlight() {
    return FlagsPermissionCargo.feature_flight_v2_enable.isEnabled()
  }

  get searchFlight() {
    return CargoController.searchFlight;
  }

  get isLoadingSearchFlight() {
    return CargoController.isLoadingSearchFlight;
  }

  get errorCauseSearchFlight() {
    return CargoController.errorCauseSearchFlight;
  }

  async getSearchFlight() {
    await CargoController.getSearchFlight({
      departureDate: this.$moment(this.form.estDepartureDate).format("YYYY-MM-DD"),
      originCityCode: this.form.airportOrigin.cityCode,
      originAirportCode: this.form.airportOrigin.airportCode,
      destinationAirportCode: this.form.airportDestination.airportCode,
      actualTotalGrossWeight: +this.form.actualTotalCargoGrossWeight.replace(/,/g, ".") || 0,
      actualTotalVolumeWeight: +this.form.actualTotalCargoVolumeWeight.replace(/,/g, ".") || 0,
      thresholdTime: +this.form.thresholdTime
    });
  }
}
