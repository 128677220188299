import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@/app/ui/assets/gif/genesis-loading.gif'


const _hoisted_1 = {
  key: 0,
  class: "h-36 w-36 mx-auto mt-12",
  alt: "loading",
  src: _imports_0
}
const _hoisted_2 = {
  key: 2,
  class: "text-black-lp-300 font-semibold pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LpButton = _resolveComponent("LpButton")!

  return (_openBlock(), _createBlock("div", {
    class: ["w-full h-full items-center flex flex-col mx-auto text-14px xxl:text-14px overflow-hidden", _ctx.customClass]
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock("img", _hoisted_1))
      : (_openBlock(), _createBlock("img", {
          key: 1,
          src: require(`@/app/ui/assets/svg/${_ctx.icon}.svg`),
          class: ["mx-auto", _ctx.customIconClass],
          alt: _ctx.icon
        }, null, 10, ["src", "alt"])),
    (_ctx.customHeaderMessage)
      ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.customHeaderMessage), 1))
      : _createCommentVNode("", true),
    (_ctx.customMessage)
      ? (_openBlock(), _createBlock("span", {
          key: 3,
          class: `text-gray-lp-600 ${_ctx.customHeaderMessage ? 'py-3' : 'py-2'}`
        }, _toDisplayString(_ctx.customMessage), 3))
      : _createCommentVNode("", true),
    (_ctx.buttonAction)
      ? (_openBlock(), _createBlock(_component_LpButton, {
          key: 4,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action'))),
          title: _ctx.buttonTitle,
          iconLeft: _ctx.buttonIconLeft,
          textColor: _ctx.buttonTextColor
        }, null, 8, ["title", "iconLeft", "textColor"]))
      : _createCommentVNode("", true)
  ], 2))
}